@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100..900&display=swap');
@font-face {
    font-family: 'helvetica_reg';
    src: url('../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_reg';
    src: url('../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_reg';
    src: url('../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_bold';
    src: url('../fonts/helvetica/Helvetica-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'helvetica_reg';
    src: url('../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_reg';
    src: url('../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_reg';
    src: url('../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_bold';
    src: url('../fonts/helvetica/Helvetica-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'futura_reg';
    src: url('../fonts/futura/Futura_Book_font.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'futura_light';
    src: url('../fonts/futura/futura_light_bt.ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'futura_bold';
    src: url('../fonts/futura/FuturaBoldfont.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'futura_med';
    src: url('../fonts/futura/futura_medium_bt.ttf');
    font-weight: 500;
}
body {
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
.fw_el_reg {
    font-weight: 400 !important;
    font-family: 'futura_reg';
}
.fw_el_med {
    font-weight: 400 !important;
    font-family: 'futura_reg';
}
.fw_el_semi {
    font-weight: 500 !important;
    font-family: 'futura_med';
}
.fw_el_bold {
    font-weight: 700 !important;
    font-family: 'futura_bold';
}
.fw_ar_reg {
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
.fw_ar_med {
    font-weight: 500 !important;
    font-family: 'futura_med';
}
.fw_ar_semi {
    font-weight: 500 !important;
    font-family: 'futura_med' !important;
}
.fw_ar_bold {
    font-weight: 700 !important;
    font-family: 'futura_bold' !important;
}
/******** customer header css *****************/
.header_blk {
    background-color: var(--def_color) !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin: 0;
    position: relative;
    z-index: 3;
}
.header_blk .hide_small_screen .container-fluid {
    padding: 0 50px 0 50px;
}
.header_blk .hide_small_screen .container-fluid .logo-wrapper {
    padding: 0;
}
.let_spa_7px {
    letter-spacing: 0.07em;
}
.main_menu_blk ul li a:hover {
    text-decoration: none;
}
.main_menu_blk ul li a span {
    display: block;
    padding-right: 10px;
}
.main_menu_blk ul li:not(:last-child) {
    margin-right: 91px;
}
.right_blk_menu li:not(:first-child) {
    margin-left: 20px;
}
/******** customer header css - Ends *****************/

/******** customer dashboard css *****************/
.customer_dashboard_div {
    min-height: calc(100vh - 90px);
    background-color: var(--sec_color);
    padding: 52px 0;
    height: calc(100% - 90px);
}
.customer_menu {
    margin-bottom: 35px;
}
.customer_menu li a {
    font-weight: 700;
    font-size: 16px;
    font-family: 'helvetica_bold';
    line-height: 18px;
    color: #4E495D;
}
.customer_menu li:not(:last-child) {
    margin-right: 30px;
}
.customer_menu li a {
    position: relative;
    padding-bottom: 9.5px;
}
.customer_menu li a:hover {
    text-decoration: none;
}
.customer_menu li a.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: #000;
}
.customer_dashboard_div + footer.site-footer {
    display: none;
}
/******** customer dashboard css - Ends *****************/

/******** customer dashboard css  *****************/
.text_black, .text_black:hover {
    color: var(--text_color);
}
.font_16 {
    font-size: 16px !important;
    line-height: 18px !important;
}
.mb_5 {
    margin-bottom: 5px !important;
}
.mb_18 {
    margin-bottom: 18px !important;
}
.m-0 {
    margin: 0 !important;
}
.font_11 {
    font-size: 11px;
    line-height: 16px;
}
.let_spa_53 {
    letter-spacing: 0.53em;
}
.card_blk .card_row.row {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
}
.card_blk .card_row.row>* {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 24px;
}
.card_col.card {
    border: none;
    border-radius: 8px;
    padding: 25px;
}
.card_blk .card_row.row>*:nth-child(1n) .card {
    background: linear-gradient(107.28deg, #F2F2F2 0.89%, #C8C1DE 100%);
}   
.text_white, .text_white:hover {
    color: var(--def_color) !important;
}
.card_col.card .active, .status_active {
    background: #768E87;
    width: fit-content;
    border-radius: 37px;
    padding: 5px 17px;
    text-transform: capitalize;
}
.let_spa_2 {
    letter-spacing: 0.02em;
}
.mt_42 {
    margin-top: 42px;
}
.add_new_btn, .add_new_btn:hover {
    background: var(--button_color);
    border-radius: 8px;
    padding: 12px 20px;
    width: 230px;
    justify-content: center;
    color: var(--def_color) !important;
    border: 1px solid var(--button_color);
}
.card_blk {
    padding-top: 9px;
}
.cursor-pointer {
    cursor: pointer;
}
.add_new_card {
    background-color: var(--def_color);
    border-radius: 8px;
    padding: 30px;
}
.form_input_blk label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 6px;
    color: var(--pri_color);
    font-weight: 400 !important;
    font-family: 'futura_reg';
}
.form_input_blk .form-control {
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    padding: 8px 11px;
    font-weight: 400;
    font-size: 16px;
    color: var(--pri_color);
    line-height: 18px;
    font-family: 'futura_reg';
    height: 42px;
}
.text_default {
    color: rgba(34, 28, 53, 0.5);
    opacity: 50%;
}
.font_12 {
    font-size: 12px !important;
    line-height: 16px !important;
}
.font_italic {
    font-style: italic;
}
.mt_30 {
    margin-top: 30px;
}
.mb_21 {
    margin-bottom: 21px;
}
.form_input_blk .form-control input {
    border: none;
    font-size: 16px;
    line-height: 18px;
    padding: 0 10px 0 0;
    outline: none;
    width: 150px;
}
.form_input_blk .form-control input.last_name_input {
    padding: 0 10px 0 10px;
    border-left: 0.3px solid #000000;;
}
.mt_66 {
    margin-top: 66px;
}
.form_input_blk .form-group {
    margin-bottom: 16px !important;
}
.form_input_blk .required {
    color: var(--pri_color);
}
.pt_9 {
    padding-top: 9px;
}
.account_row {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
}
.account_row>* {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 24px;
}
.mt_80 {
    margin-top: 80px !important;
}
/******** customer dashboard css - Ends *****************/

/******** customer address css - Ends *****************/
.font_14 {
    font-size: 14px;
    line-height: 16px;
}
.mb_13 {
    margin-bottom: 13px;
}
.page-content.entry-content .address-card.card p.m-0 {
    margin: 0 !important;
}
.text_underline,a.text_underline:hover {
    text-decoration: underline !important;
}
.address-card.card {
    border: none;
    border-radius: 8px;
}
.address-card.card .card_header {
    padding: 20px;
}
.address-card .card_footer {
    background: #768E87;
    border-radius: 0 0 8px 8px;
    padding: 10px 20px;
}
.pl_4 {
    padding-left: 4px;
}
.ml_10 {
    margin-left: 10px;
}
.phone_span {
    width: 70px;
    padding-right: 10px;
}
.phone_span::after {
    content: ":";
    position: absolute;
    top: -1px;
    right: 4px
}
.card_footer.set_def_card_foot {
    background-color: var(--def_color);
    padding-bottom: 18px;
    padding-top: 0;
}
/******** customer address css - Ends *****************/

/******** customer subscription css *****************/
.font_22 {
    font-size: 22px;
    line-height: 25px;  
}
.mb_43 {
    margin-bottom: 43px;
}
.mb_7 {
    margin-bottom: 7px;
}
.mb_29 {
    margin-bottom: 29px;
}
.subscrip_progress.progress {
    height: 15px;
    background-color: #F2F2F2;
    border-radius: 16px;
}
.subscrip_progress.progress .progress-bar {
    background-color: #768E87;
    border-radius: 16px;
}
.sub_current_div {
    background: var(--def_color)FFF;
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 29px 48px;
    margin-bottom: 50px;
}
.w-22 {
    width: 22% !important;
}
.text_gray {
    color: #4E495D;
}
.mb_26 {
    margin-bottom: 26px;
}
.invoice_table th {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: 'futura_reg' !important;
    padding-bottom: 12px;
}
.invoice_table  tbody {
    border-bottom: 9px solid whitesmoke;
}
.invoice_table tbody td {
    background-color: var(--def_color);
    padding: 10px 17px;
}
.invoice_table tbody td:first-child {
   border-radius: 8px 0 0 8px;
}
.invoice_table tbody td:last-child {
   border-radius: 0 8px 8px 0;
}
.sub_name {
    width: 20%;
}
.order_type, .sub_status {
    width: 15%;
}
.sub_date {
    width: 20%;
}
.sub_total, .sub_active {
    width: 15%;
}
.pb_9 {
    padding-bottom: 9px;
}
.subscription_ul.subscription_body {
    padding: 23px 0px;
    background: var(--def_color)FFF;
    border-radius: 8px;
    margin-bottom: 9px;
}
.subscription_ul.subscription_body li:first-child {
    padding-left: 17px;
}
.pr_17 {
    padding-right: 17px;
}
.w-50 {
    width: 50%;
}
/******** customer subscription css - Ends *****************/

/******** customer orders css *****************/
.ml_20 {
    margin-left: 20px;
}
.ml_18 {
    margin-left: 18px;
}
.mb_0 {
    margin-bottom: 0 !important;
}
.ml_23 {
    margin-left: 23px;
}
.order_accordatio {
    padding: 30px 37px;
    background: var(--def_color)FFF;
    border-radius: 8px;
    margin-bottom: 28px;
}
.order_accordatio .order_acc_header {
    padding-bottom: 22px;
}
.items_text {
    color: var(--text_color);
}
.mb_8 {
    margin-bottom: 8px;
}
.order_pro_detail {
    background: #F2F2F2;
    border-radius: 8px;
    padding: 11px;
    margin-bottom: 8px;
}
.product_image {
    width: 38px;
}
.product_image + * {
    width: calc(100% - 38px);
}
.pr_10 {
    padding-right: 10px;
}
.order_acc_body {
    padding: 2px 0 44px 0;
}
.status_active.cancelled {
    background-color: #66435A;
}
.status_active.failed {
    background-color: #FF3838;
}
.status_active.completed {
    background-color: #56F000;
}
.status_active.onhold {
    background-color: #FFB302;
}
.order_acc_footer .accordion-button {
    padding: 0;
    display: flex;
    background-color: transparent;
    outline: none;
    border: none;
}
/******** customer orders css - Ends *****************/

/******** customer orders detail  css *****************/
.font_26 {
    font-size: 26px;
    line-height: 30px;
}
.mb_4 {
    margin-bottom: 4px;
}
.pl_10 {
    padding-left: 10px;
}
.mb_25 {
    margin-bottom: 25px;
}
.order_det_total {
    background-color: #F2F2F2;
    border-radius: 8px;
    padding: 20px 15px;
    margin-bottom: 9px;
}
.order_det_blk {
    padding: 28px 38px;
    border-radius: 8px;
}
/******** customer orders detail  css - Ends *****************/

/******** customer subscription detail  css *****************/
.mr_36 {
    margin-right: 36px;
}
.mb_32 {
    margin-bottom: 32px;
}
.auto_reniew_blk {
    padding: 23px;
    border-radius: 8px;
}
.switch_label input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
    appearance: none;
    cursor: pointer;
}
.switch_span {
    width: 40px;
    height: 18px;
    background: linear-gradient(0deg, #F2F2F2, #F2F2F2), linear-gradient(0deg, #F2F2F2, #F2F2F2), #F2F2F2;
    border-radius: 37px;
    position: relative;
    cursor: pointer;
}
.switch_span::after {
    display: block;
    background: linear-gradient(0deg, #768E87, #768E87), linear-gradient(0deg, #F2F2F2, #F2F2F2), #F2F2F2;
    width: 18px;
    height: 18px;
    border-radius: 37px;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    transition: 0.5s ease-in-out;
}
input:checked 
 + .switch_span::after {
    left: 21px;
}
.sub_billing_blk {
    padding: 25px;
    border-radius: 8px;
}
.sub_btm_det li:not(:last-child) {
    margin-right: 42px;
}
.mt_19 {
    margin-top: 19px;
}
.border_radius_50 {
    border-radius: 50px !important;
}
/******** customer subscription detail  css - Ends *****************/

/******** dashboard  css *****************/
.dashboard_row {
    width: calc(100% + 28px);
    margin-left: -14px;
    margin-right: -14px;
}
.dashboard_row>* {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 28px;
}
.dashboar_col {
    background-color: var(--def_color);
    border-radius: 8px;
    padding: 20px 28px;
}
.order_on_div, .order_status_div {
    width: 20%;
}
.dashboar_col .right_pro {
    width: 10%;
}
.order_acc_body.das_order_acc_body .order_pro_detail {
    margin: 0;
}
.order_acc_body.das_order_acc_body .order_pro_detail:not(:last-child) {
    margin: 0 0 18px 0;
}
/******** dashboard  css - Ends *****************/
/******* header sub menu css ***********/
.mt_17 {
    margin-top: 17px;
}
.header_sub_menu .dropdown-menu {
    width: 100% !important;
    min-width: inherit;
    left: 0 !important;
    transform: inherit !important;
    top: 83px !important;
    border: none !important;
    border-radius: 0;
    background-color: var(--def_color);
    box-shadow: 0px 4px 26px 0px #32293F40;
    padding: 30px 15px 15px 15px;
}
.shop_menu_li {
    background-color: #2E2645;
    border-radius: 5px;
    padding: 18px 5px;
}
.shop_menurow>* {
    flex: inherit;
    padding-left: 25px;
    padding-right: 25px;
    max-width: inherit;
    padding-bottom: 42px;
}
.shop_menurow {
    margin-left: -25px;
    margin-right: -25px;
    width: calc(100% + 50px);
}
.main_menu_blk ul.menu>li.show>a {
    position: relative;
}
.main_menu_blk ul.menu>li.show>a::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--text_color);
    position: absolute;
    bottom: -4px;
    left: 0;
}
.main_menu_blk>ul>li>a >span {
    text-transform: uppercase;
}
.right_blk_menu .dropdown-menu>a {
    padding: 8px 15px;
    display: flex;
}
.right_blk_menu .dropdown-menu {
    background: var(--def_color);
    box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
}
.mt_27 {
    margin-top: 27px;
}
.shop_all_link {
    padding: 6px 0;
    border-top: 1px solid var(--text_color);
    border-bottom: 1px solid var(--text_color);
}
.user_login {
    margin: 50px auto;
}
/******* header sub menu css - Ends ***********/

/******* left sub menu css ***********/
.cus_dash_row  .left_menu {
    border: none;
    padding: 0;
}
.cus_dah_left {
    width: 275px;
    flex: inherit;
    max-width: inherit;
}
.cus_dash_rgt {
    width: calc(100% - 275px);
    flex: inherit;
    max-width: inherit;
    padding-left: 35px;
}
.cus_dash_row .left_menu>ul {
    background-color: var(--def_color);
    padding: 25px 0;
    border-radius: 8px;
}
.cus_dash_row .left_menu .active_icon, .cus_dash_row .left_menu>ul>li>a.active .inactive_icon {
    display: none;
}
.cus_dash_row .left_menu>ul>li>a.active .active_icon {
    display: block;
}
.cus_dash_row .left_menu>ul>li {
    border: none;
    margin: 0;
    padding: 0;
}
.cus_dash_row .left_menu>ul>li>a {
    font-weight: 700 !important;
    font-family: 'helvetica_bold' !important;
    font-size: 16px;
    line-height: 18px;
    color: var(--pri_color);
    display: flex;
    align-items: center;
    padding: 14px 25px;
    border-radius: 8px;
}
.cus_dash_row .left_menu>ul>li>a>img {
    margin-right: 9px;
}
.cus_dash_row .left_menu>ul>li>a.active {
    background: var(--pri_color);
    color: var(--def_color);
}
.dashboar_col {
    height: 100%;
}
.main_menu_blk ul li a {
    color: var(--text_color);
    font-size: 24px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 700;
    line-height: 36px;
}
/******* left sub menu css - Ends ***********/

/******* status bg css  ***********/
.status_active.processing {
    background-color: #56813d;
}   
.status_active.pending {
    background-color: #a33838;
}
/******* status bg css - Ends ***********/

/******* subscription table css  ***********/
.subscribtion_table th {
    padding-bottom: 7px;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: 'futura_reg' !important;
}
.subscribtion_table th:last-child, .subscribtion_table td:last-child {
    text-align: right;
}
.subscribtion_table {
    border-collapse: separate;
    border-spacing: 0 9px;
}
.subscribtion_table tr td {
    background-color: var(--def_color);
    padding: 20px 0;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: 'futura_reg' !important;
}
.subscribtion_table tr td:first-child {
    border-radius: 8px 0 0 8px;
    padding-left: 17px;
}
.subscribtion_table tr td:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 17px;
}
/******* subscription table css - Ends ***********/

/******* subscription table css ***********/
.customer_dash_full_view , .customer_dash_full_view .container-fluid {
    padding: 0;
}
.customer_dash_full_view .container-fluid>* {
    margin: 0;
    min-height: calc(100vh - 90px);
}
.customer_dash_full_view .cus_dash_row .left_menu>ul, .customer_dash_full_view  .cus_dash_row .left_menu>ul>li>a {
    border-radius: 0;
}
.customer_dash_full_view .cus_dah_left {
    padding: 0;
    background-color: var(--def_color);
}
.customer_dash_full_view .cus_dash_rgt {
    padding: 40px;
}
/******* subscription table css - Ends ***********/

/******* order table css ***********/
.table_search .search_form input {
    border-color: var(--def_color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    font-family: 'helvetica_bold' !important;
    padding: 10px 10px 10px 31px;
}
.table_search .search_form .search_icon {
    top: 11px;
    left: 11px;
    width: 15px;
    height: 15px;
    background-color: transparent;
}
.table_search_select .form-control {
    width: 130px !important;
    border-radius: 45px;
    border-color: var(--def_color);
    margin: 0 0 0 10px !important;
    appearance: none;
    color: rgba(34, 28, 53, 0.5);
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    font-family: 'helvetica_bold' !important;
    padding: 10px 30px 10px 10px;
    background-image: url('../images/customer_images/order_dropdown.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
}
ul.pagination.cus_pagination {
    margin-top: 20px;
}
/******* order table css - Ends ***********/

/******* dashboard css ***********/
.pro_carousel.browsing_his_carousel {
    width: calc(100% + 40px);
}
.pro_carousel.browsing_his_carousel .owl-prev, .pro_carousel.browsing_his_carousel .owl-next {
    border-radius: 8px !important;
}
.pro_carousel .owl-prev, .pro_carousel .owl-next {
    background: var(--pri_color) !important;
}
.mt_65 {
    margin-top: 65px;
}
/******* dashboard css - Ends ***********/

/******* Login css ***********/
.login_container {
    background-color: #F2F2F2;
    min-height: calc(100vh - 90px);
}
.login_container 
 .user_login {
    width: 450px;
}
/******* Login css - Ends ***********/

/******* contact css ***********/
.contact_container {
    padding: 40px 0;
}
.contact_cppatcha .form-control {
    width: 200px;
    margin: 0 20px;
}
.captcha_container.contact_cppatcha h2 {
    border-radius: 4px;
    border-color: #000;
    font-weight: 400 !important;
    font-family: 'futura_reg';
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
}
.terms_container 
 .terms_and_condition p, .terms_container 
 .terms_and_condition span {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px !important;
    font-family: 'futura_reg' !important;
    font-weight: 400;
    display: block;
    color: var(--text_color) !important;
    text-transform: lowercase;
}
.terms_container 
.terms_and_condition h4  {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px !important;
    font-family: 'helvetica_bold' !important;
    font-weight: 700;
    display: block;
    color: var(--pri_color) !important;
}
.terms_container 
 .terms_and_condition p::first-letter, .terms_container 
 .terms_and_condition span::first-letter {
    text-transform: uppercase;
}
.terms_container 
 .terms_and_condition p a {
    text-decoration: underline;
 }
.terms_container .terms_and_condition p strong {
    font-weight: 700;
    display: inline;
    margin-top: 15px;
    font-family: 'helvetica_bold' !important;
}
.terms_container .terms_and_condition li, .terms_container .terms_and_condition li a {
    font-size: 16px;
    line-height: 22px;
    font-family: 'futura_reg' !important;
    color: var(--pri_color);
}
.footer_menu.hide_get_touch li:nth-child(3) {
    display: none;
}
.form_input_blk textarea.form-control {
    height: 80px;
}
button#refresh {
    opacity: 50%;
}
.social_media_link a {
    display: flex;
}
/******* contact css - Ends ***********/

/******* contact css ***********/ 
body .error404 .subtitle {
    font-size: 20px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    line-height: 22px;
}
body .page-content.entry-content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
body .error404 .page-title {
    font-weight: 700 !important;
    font-family: 'helvetica_bold' !important;
}
/******* contact css - Ends ***********/

/******** css changes on 27/03/2023 page css ***********/
.table_search_select 
 .search_input_btn {
    width: 38px !important;
    height: 38px;
    background-color: var(--def_color);
    border-radius: 4px;
    border: 1px solid var(--def_color);
    /* background-image: url('../images/customer_images/header_search_icon.svg'); */
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.modal-content .col-md-12 {
    width: 100%;
}
/******** css changes on 27/03/2023 page css - Ends ***********/

/******** css changes on 27/03/2023 page css ***********/
.banner_text_overlay {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.refer_banner_slide.public_banner_slide  {
    padding: 0;
} 
.refer_banner_slide.public_banner_slide .carousel-item {
    /* padding: 105px 53px; */
    /* background-image: url('../images/public_images/new_banner_image.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.banner_text_overlay {
    height: 305px;
}
.banner_text_overlay img {
    width: 100%;
}
/* .refer_banner_slide.public_banner_slide::after {
    background-image: url('../images/public_images/banner_overlay.svg');
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
} */
.carousel-inner {
    z-index: 2;
}
body.home_page .header_blk {
    background-color: transparent;
    position: absolute;
    z-index: 4;
    width: 100%;
    left: 0;
}
.banner_below {
    display: block;
    background-color: var(--def_color);
    padding: 16px 0;
}
.best_selling_para.top_selling-para {
    width: 70%;
}
/* .top_selling_row>*:first-child {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 0 !important;
} */
.public_pro_wrapper .refer-img-wrapper img {
    width: 100%;
}
.top_selling_row .public_pro_wrapper .refer-img-wrapper {
    min-height: 185px;
}
.public_pro_wrapper .refer-img-wrapper {
    background-color: #fff;
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    height: auto;
}
.refer_produc_wrapper.public_pro_wrapper {
    border: 1px solid #F2F2F2;
}
.public_pro_wrapper .refer_product_short_desc {
    padding: 12px;
}
.public_pro_wrapper .refer_product_short_desc .refer_product_left {
    width: calc(100% - 34px);
}
.public_pro_wrapper .refer_product_short_desc .refer_product_right {
    width: calc(24px);
}
.refer_produc_wrapper.public_pro_wrapper {
    width: 100%;
    margin: 0;
}
.top_selling_row.row>* {
    float: left;
    padding-bottom: 30px;
}
.top_selling_row.row {
    overflow: hidden;
    display: block;
}
.public_pro_wrapper .refer_product_short_desc .refer_product_left h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    width: 100%;
}
/* .top_selling_row>*:first-child .public_pro_wrapper .refer-img-wrapper {
    min-height: 640px;
} */
.shop_menu>*:not(:last-child) {
    padding-bottom: 20px;
}
.top_sel_below_sec {
    background-image: url('../images/about_us_img.svg');
    min-height: 550px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: var(--pri_color);
}
.top_sel_below_sec .container {
    min-height: 100%;
}
.font_62 {
    font-size: 62px;
    line-height: 76px;
    letter-spacing: 1.258px;
}
.mb_34 {
    margin-bottom: 34px !important;
}
.mt_10 {
    margin-top: 10px !important;
}
/******** css changes on 27/03/2023 page css - Ends ***********/

/******** login tab css *****************/
.login_tabs.nav-tabs .nav-link {
    border: none;
    border-radius: 8px 8px 0 0;
    padding: 10.5px 30px;
    margin-right: 10px;
    background-color: var(--def_color);
    outline: none;
}
.login_tabs.nav.nav-tabs {
    border: none;
}
.aff_login_blk .add_new_card {
    border-top-left-radius: 0;
}
.login_tabs.nav-tabs .nav-link.active {
    background-color: var(--button_color);
    color: var(--def_color);
    font-weight: 400;
}
.register_recaptcha.contact_cppatcha .form-control {
    width: calc(100% - 250px);
}

body .error404 .subtitle {
    font-size: 20px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    line-height: 22px;
}
body .page-content.entry-content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
body .error404 .page-title {
    font-weight: 700 !important;
    font-family: 'helvetica_bold' !important;
}
/******** login tab css - Ends *****************/

/******** css changes on 13/07/2023 page css ***********/
.bundle_product.public_bundle_product {
    padding: 69px 0;
}
.public_bundle_product .public_pro_wrapper .refer-img-wrapper {
    min-height: 280px;
}
.font_42 {
    font-size: 41.891px;
    line-height: 48px;
}
.public_bundle_product .best_selling_para.top_selling-para {
    margin-left: 0;
}
.public_bundle_product .owl-carousel .owl-item img {
    object-fit: contain;
}
.public_bundle_product.public_ban_section  .owl-carousel .owl-item img {
    object-fit: cover;
    height: 600px;
    object-position: center;
}
.public_bundle_product .public_pro_wrapper .refer_product_short_desc {
    max-height: 86px;
    height: auto;
}
.public_bundle_product .owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    top: -79px;
    right: 0;
}
.public_bundle_product .owl-theme .owl-nav [class*='owl-'], .public_bundle_product .owl-theme .owl-nav [class*='owl-']:hover,
.public_bundle_product .owl-theme .owl-nav [class*='owl-']:focus {
    width: 46px;
    height: 46px;
    background: var(--pri_color);
    border-radius: 50%;
    margin: 0 0 0 24px;
    color: transparent;
    font-size: 40px;
    line-height: 46px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    outline: none;
}
.public_bundle_product .owl-theme .owl-nav [class*='owl-'].owl-prev {
    background-image: url('../images/public_images/bundle_left_arrow.svg');
}
.public_bundle_product .owl-theme .owl-nav [class*='owl-'].owl-next {
    background-image: url('../images/public_images/bundle_right_arrow.svg');
}
.contact_card div>img {
    width: 18px;
}
.public_banner_slide .carousel-item .row {
    width: calc(100% + 30px);
}
/******** css changes on 13/07/2023 page css - Ends ***********/

/******** css changes on 24/07/2023 page css ***********/
@font-face {
    font-family: 'helvetica_reg';
    src: url('./../fonts/helvetica/Helvetica.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'helvetica_reg';
    src: url('./../fonts/helvetica/Helvetica.ttf');
    font-weight: 200;
}
@font-face {
    font-family: 'helvetica_bold';
    src: url('./../fonts/helvetica/Helvetica-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'helvetica_bold';
    src: url('./../fonts/helvetica/Helvetica-Bold.ttf');
    font-weight: 700;
}
.fw-my-reg {
    font-family: 'helvetica_reg' !important;
    font-weight: 400 !important;
}
.fw-my-light {
    font-family: 'helvetica_reg' !important;
    font-weight: 200 !important;
}
.fw-my-bold {
    font-family: 'helvetica_bold' !important;
    font-weight: 700 !important;
}
.fw-my-black {
    font-family: 'helvetica_bold' !important;
    font-weight: 700     !important;
}
.public_ban_text {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 50px;
}
.promotes_text {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;
}
.banner_para {
    font-size: 16.1pt;
    line-height: 21.01pt;
}
.public_banner_slide a {
    color: var(--def_color);
}
.price_text {
    font-size: 23.1pt;
    margin-bottom: 5px;
}
.price_amount {
    font-size: 55.1pt;
    margin-bottom: 25pt;
}
.public_ban_btn {
    border: none;
    font-size: 15.1pt;
    color: var(--text_color);
    background: var(--button_color);
    padding: 10px 13px;
    width: fit-content;
}
.ban_button_line {
    margin-left: 15px;
    width: 40px;
    height: 2px;
    background-color: var(--text_color);
}

@media only screen and (max-width: 767px) {
    body .public_ban_text {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
    body .public_ban_text {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 30px;
    }
    body .banner_section.public_ban_section .refer_banner_slide .carousel-item .row {
        height: auto;
    }
    .public_banner_slide .row>* {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
/******** css changes on 24/07/2023 page css - Ends ***********/


/* .top_selling_row>*:first-child .public_pro_wrapper .refer-img-wrapper img {
    width: 75%;
} */
iframe#front-chat-iframe {
    display: block;
}
.sec_text {
    color: var(--sec_color);
}
/* Inactive product */
.inactive_product{
    padding: 228px;
}


/***** css changes on 17/10/2023 *******/
.shop_recovery {
    width: 14%;
    height: 8%;
    position: absolute;
    bottom: 6%;
    left: 5%;
    border-radius: 11px;
}
.shop_energy {
    width: 16%;
    height: 7%;
    position: absolute;
    bottom: 33%;
    left: 7%;
    border-radius: 11px;
}
/***** css changes on 17/10/2023 Ends *******/

/***** css changes on 02/11/2023 *******/
.pro_dec_blk {
    padding-top: 50px;
    padding-bottom: 50px;
}
.pro_dec_blk .pro_det_div_des {
    padding-left: 50px;
    padding-right: 50px;
}
.pro_det_div_des h1 {
    font-size: 20px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    font-family: 'helvetica_bold' !important;
    margin-bottom: 8px !important;
    color: var(--pri_color) !important;
}
/***** css changes on 02/11/2023 Ends *******/

/***** css changes on 26/08/2023  *********/
.front-chat-launcher {
    display: none;
}
/***** css changes on 26/08/2023 - Ends *********/


/*** new home page changes ****/
.header_search_blk .form-control {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 40px;
    font-family: 'futura_reg', sans-serif;
    padding-left: 41px;
}
.header_search_blk {
    width: 196px;
}
.header_search_blk img {
    top: 9px;
    left: 7px;
}
.fw_inter_semi {
    font-family: "Inter", sans-serif;
    font-weight: 600;
}
.fw_inter_med {
    font-family: "Inter", sans-serif;
    font-weight: 500;
}
.fw_inter_reg {
    font-family: "Inter", sans-serif;
    font-weight: 400;
}
.fw_inter_bold {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}
.barlow-semi-condensed-thin {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 100;
}
.barlow-semi-condensed-extralight {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 200;
}
.barlow-semi-condensed-light {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 300;
}
.barlow-semi-condensed-regular {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 400;
}
.barlow-semi-condensed-medium {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 500;
}
.barlow-semi-condensed-semibold {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 600;
}
.barlow-semi-condensed-bold {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 700;
}
.barlow-semi-condensed-extrabold {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 800;
}
.barlow-semi-condensed-black {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 900;
}
.buy_now.sup_all_sup_btn {
    padding: 15px 26.5px;
}
.endless_text {
    font-size: 64px;
    line-height: 120px;
    letter-spacing: -1.28px;
}
.endless_para {
    font-size: 28px;
    line-height: 42px;
}
.support_tab {
    border: 2px solid var(--new_button_color);
    border-radius: 30px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    padding: 14.4px 22px;
    cursor: pointer;
    color: var(--text_color);
    white-space: nowrap;
    background-color: var(--def_color);
    text-transform: uppercase;
}   
.support_tab:hover {
    color: var(--text_color);
}
.support_tab_blk {
    width: 100%;
}
.support_tab_blk_ul {
    border: none;
    position: relative;
    z-index: 3;
    width: calc(100% - 250px);
}
.support_tab_blk .create_bundle_btn {
    position: relative;
    z-index: 3;
}
.support_tab.active {
    background-color: var(--new_button_color);
    color: #fff;
}
.endless_section_blk {
    background-color: #f5f5f5;
    padding: 10px 0 40px 0;
}
.endless_support_tab_sec {
    padding: 15px 0;
}
.mb_50 {
    margin-bottom: 50px;
}
.mb_70 {
    margin-bottom: 70px;
}
.space_seperator {
    background-color: #f5f5f5;
    padding: 20px;
}
.line_hei_75px {
    line-height: 75px !important;
}
.smat_sav_blk {
    padding: 50px;
}
.font_30 {
    font-size: 30px;
    line-height: 40px;
}
.smar_sav_img {
    width: 100%;
    height: 660px;
    object-fit: cover;
    object-position: top;
}
.kick_start_img {
    height: 600px;
    object-fit: cover;
    object-position: top;
}
.w-90 {
    width: 90%;
}
.about_us_blk {
    padding: 30px 0px;
}
.sub_shop_now_btn {
    box-shadow: 0px 1px 2px 0px #0000000D;
    background-color: var(--def_color);
    border-radius: 5px;
    padding: 18px 24px;
}
.subscribe_save_section {
    background-repeat: no-repeat;
    background-image: url('../images/public_images/subscribe_svae_img.svg');
    padding: 40px 0;
    background-size: cover;
}
.klaviyo-form-Wqv8Jg.join_newsletter_blk.m-0.klaviyo-form.form-version-cid-1 {
    width: 333px;
}
input#email_112611932 {
    height: 48px !important;
    box-shadow: 0px 1px 2px 0px #0000000D;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px !important;
    color: #828282 !important;
    font-family: "Barlow Semi Condensed", sans-serif !important;
    font-weight: 500 !important;
}
footer .font_10 {
    line-height: 15px !important;
} 
.third_party_spa {
    letter-spacing: 1.28px; 
}
header .buy_now, .home_page .buy_now {
    background-color: var(--new_button_color);
}
.meet_our_section {
    background-image: url('../images/public_images/meetoursellers_image.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 15px;
    height: 683px;
}
footer .font_16 {
    line-height: 24px;
}
.main_menu_blk ul li a.shop_all_link {
    font-size: 20px; 
    line-height: 30px;
    font-weight: 600 !important;
}
.main_menu_blk>ul>li ul>li:not(:last-child) {
    margin-right: 52px;
}
.main_menu_blk>ul>li ul>li a {
    padding: 0;
    border: none;
}
.main_menu_blk>ul>li ul>li {
    padding: 0 0 15px 0 !important;
}
/*** new home page changes Ends ****/

/*** CSS changes on 16/07/2024 ****/
.flavour_accordation .card-header {
    border: none;
    background: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 0;
}
.flavour_accordation .card-header .flavour_acc_header {
    padding: 11px 14px;
}
.flavour_accordation .card-bodys {
    padding: 11px 14px;
}
.flavour_accordation .card {
    background-color: var(--sec_color);
    border: none;
    border-radius: 4px !important;
}
.flavour_accordation .card-header .flavour_acc_header.collapsed .flavour_header_left.hide {
    display: none !important;
}
.flavour_accordation .card-header .flavour_acc_header .flavour_header_left.hide {
    display: flex !important;
}
.flavour_accordation .card-header .flavour_acc_header .flavour_header_left.show {
    display: none !important;
}
.flavour_accordation .card-header .flavour_acc_header.collapsed .flavour_header_left.show {
    display: flex !important;
}
.flavour_accordation .card-header .flavour_acc_header.collapsed .flavour_dropdown {
    transform: rotate(0Deg);
}
.flavour_accordation .card-header .flavour_acc_header .flavour_dropdown {
    transform: rotate(-180Deg);
}
.flavour_header_left {
    width: calc(100% - 14px);
    padding-right: 20px;
}
.flavour_image {
    background-color: #E6E5E8;
    width: 28px;
    height: 33px;
    padding: 5px;
    border-radius: 4px;
}
.flavour_product_each {
    padding-bottom: 20px;
}
.flavour_acc_body {
    padding-right: 45px;
}
.product_det_flavour {
    height: 29px;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    line-height: 17px;
    background-image: url('../images/refer_images/delivery_dropdown.svg');
    appearance: none;
    background-position: calc(100% - 7px);
    padding: 1px 20px 1px 7px;
    background-repeat: no-repeat;
    outline: none !important;
}
.ml_35 {
    margin-left: 35px;
}
.support_tab_blk li {
    margin-right: 19px;
}
.endless_support_tab_sec .public_bundle_product .owl-theme .owl-nav {
    width: 100%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
    top: -103px;
    z-index: 2;
    padding-right: 26px;
}
.support_tab_btn_blk {
    padding-right: 140px;
}
/*** CSS changes on 16/07/2024 Ends ****/

/***** css changes on 11/07/2024 *********/
.mobile_show {
    display: none;
}
/***** css changes on 11/07/2024 - Ends *********/

/***** css changes on 25/07/2024 *********/
.buy_now.continue_shop_btn {
    padding: 12px 30px;
}
.input_bundle_btn .build_bundle {
    padding: 11px 15px;
}
.pswp__img {
    object-fit: contain;
}
/***** css changes on 25/07/2024 - Ends *********/

.banner_section .carousel-control-next, .banner_section  .carousel-control-prev {
    top: 50%;
    transform: translateY(-50%);
    width: 46px;
    opacity: 1;
    height: 46px;
    background-color: var(--pri_color);
    border-radius: 50%;
    z-index: 3;
}
.banner_section .carousel-control-next img, .banner_section  .carousel-control-prev img {
    width: auto;
}
.banner_section .carousel-control-prev {
    left: 20px;
}
.banner_section .carousel-control-next {
    right: 20px;
}
.banner_section.public_bundle_product .owl-theme .owl-nav {
    position: static;
}
.banner_section.public_bundle_product .owl-theme .owl-nav [class*='owl-'].owl-prev {
   left: 24px;
}
.banner_section.public_bundle_product .owl-theme .owl-nav [class*='owl-'].owl-next {
    right: 24px;
 }
 .banner_section.public_bundle_product .owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
 }
 .endless_tab_sec>.container-fluid {
    padding-left: 40px;
    padding-right: 40px;
 }

body .nav-tabs .nav-item {
    margin-bottom: 0;
}
.support_tab_blk_ul .nav-tabs {
    border-bottom: none;
}
body .scrollbar-thumb {
    height: 4px;
    background: var(--pri_color);
    border-radius: 4px;
}
body .scrollbar-track-x {
    height: 4px;
}
/***** css changes on 26/12/2024 *********/

/***** css changes on 10/01/2025 *********/
.quiz_blk {
    min-height: calc(100vh - 330px);
    background-color: var(--def_color);
    padding: 40px 40px 85px 40px;
    border-radius: 4px;
    background-image: url('../images/public_images/quiz_background.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
}
.questinaries_con_blk {
    background-color: var(--sec_color);
}
.font_44 {
    font-size: 44px;
    line-height: 68px;
}
.ml_27 {
    margin-left: 27px;
}
.mr_27 {
    margin-right: 27px;
}
.quiz_options {
    border: 1px solid #F3F3F3;
    box-shadow: 0px 4px 4px 0px #402E7740;
    border-radius: 20px;
    width: 235px;
    height: 243px;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    outline: none !important;
}
.ml_3 {
    margin-left: 3px;
}
.next_button.buy_now {
    min-width: 235px;
    border-radius: 80px;
    height: 80px;
    outline: none !important;
}
.quiz_options.selected, .ques_pro_each.selected {
    background-color: var(--button_color);
    color: var(--def_color);
    border-color: var(--button_color);
}
.quiz_options .inactive_icon, .quiz_options.selected .active_icon  {
    display: none;
}
.quiz_options.selected .inactive_icon {
    display: block;
}
.first_quiz_button {
    width: 595px;
}
.gender_quiz_button {
    width: 515px;
}
.quiz_options.sec_quiz_options {
    width: 282px;
    height: 80px;
    border-radius: 75px;
}
.quiz_bundle .shop_more.add_to_bundle {
    width: fit-content;
}
.quiz_options.sec_quiz_options:not(:last-child) {
    margin-right: 27px;
}
.letter_spacing_2per {
    letter-spacing: 0.02em;
}
.second_options_blk {
    min-height: 200px;
}
.back_button {
    background: none;
    border: none;
    padding: 0;
    outline: none !important;
}
.back_button .text_pri {
    color: #402E77;
}
.ques_pro_each {
    background-color: #C8C3D9;
    height: 7px;
    border-radius: 3.5px;
}
.quiz_pro_found {
    border: 1px solid #402E77;
    background: #F5F1FF;
    border-radius: 27px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 3.5px 18px;
}
/***** css changes on 10/01/2025 Ends *********/
.next_button.buy_now[disabled] {
    opacity: 0.7;
}
.select_flavour.select_flavour_bundle {
    border: 1px solid #F2F2F2;
    border-radius: 6px;
    padding: 5px 25px 5px 10px !important;
    height: 29px;
    background-position: calc(100% - 10px);
    font-size: 13px;
    line-height: 15px;
}
button.shop_more.add_cart_btn.font_16.banner_text.text-center.fw_ar_reg.add_to_bundle.add_bundle_btn.proceed {
    margin-right: 408px;
}
.mb_5 {
    margin-bottom: 5px !important;
}
.cart_quantity.quan_cart_blk.qty_cus_bun {
    width: 88px;
}

/* css changes on 27/01/2025 */
.next_button {
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .next_button_op {
    background-color: var(--button_color);
    border-radius: 8px;
    border: none;
    padding: 17px 26.5px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    opacity: 0.6;
}
  .next_button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--button_color);
    width: 0%; 
    transition: width 0.1s ease-out;
    z-index: 2;
    pointer-events: none;
  }
  .fade-to-dark-purple.buy_now {
    position: relative;
    z-index: 1;
    background-color: rgb(47 7 95 / 70%);
  }
  .fade-to-dark-purple.buy_now span, .fade-to-dark-purple.buy_now img {
    position: relative;
    z-index: 3;
  }
  .fade-to-dark-purple::before {
    animation: moveColorProgress 5s linear forwards;
  }
  
  @keyframes moveColorProgress {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }

  button.shop_more.add_cart_btn.font_18.banner_text.text-center.fw_ar_reg.add_to_bundle.add_bundle_btn.proceed_1 {
    margin-right: 2px;
    margin-bottom: 25px;
    width: 304px;
}
  .modal-body1.montserrat {
    padding: 21px;
}
  
/* css changes on 27/01/2025 ends*/

/* css changes on 12/02/2025*/
.quiz_bundle_btn {
    position: fixed;
    bottom: 25px;
    right: 0;
    z-index: 3;
}
.quiz_bundle_btn.remove-fixed {
    display: none !important;
}
.quiz_bundle_btn .shop_more {
    margin: 0 auto;
}
.hide_button.hide_blk {
    display: none;
}
.hide_button {
    display: flex;
}
/* css changes on 12/02/2025 ends*/
